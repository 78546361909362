import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
@Module({ dynamic: true, store, name: "user" })
export default class User extends VuexModule {
  zlacToken = "";

  @Mutation
  private SET_ZLAC_TOKEN(token: string) {
    this.zlacToken = token;
  }

  @Action
  reset() {
    this.SET_ZLAC_TOKEN("");
  }

  @Action
  setZlacToken(token: string) {
    this.SET_ZLAC_TOKEN(token);
  }

}

export const UserModule = getModule(User);
