




















import { Vue } from 'vue-property-decorator'
// import {NameSpace} from '../store/type-defs'

export default Vue.extend({
  // name : "deleteAcctDialog",
  props: ['token', 'target', 'name', 'type'],
  data () {
    return {
      header_title: '編輯課程名稱',
      title_name: '課程名稱',
      place_holder: '輸入課程名稱',
      Name: '',
      old_name: '',
      isLegal: true,
      error_text: '不可輸入特殊符號'
    }
  },
  watch: {
    Name () {
      var expression = /[/\\*?:"<>|:.^]/g
      var expressionFullShape = /[\uff00\uff0f\uff3c\uff0a\uff1f\uff1a\uff02\uff1c\uff1e\uff5c\uff0e\uff3e]/g
      var regex = new RegExp(expression)
      var regexFullShape = new RegExp(expressionFullShape)
      if (this.Name === '') {
        if (this.type === "edit_unit") {
          this.error_text = '請輸入單元名稱'
        }else {
          this.error_text = '請輸入課程名稱'
        }
        this.isLegal = false
      }else if(this.isNullwithSpace(this.Name)) {
        if (this.type === "edit_unit") {
          this.error_text = '單元名稱不可全輸入空格'
        }else {
          this.error_text = '課程名稱不可全輸入空格'
        }
        this.isLegal = false
      }else if (regex.test(this.Name) || regexFullShape.test(this.Name)) {
        this.error_text = '不可輸入特殊符號'
        this.isLegal = false
      }else {
        this.isLegal = true
      }

      if (this.old_name !== this.Name) {
        this.$bus.$emit('unSaved')
      }else {
        this.$bus.$emit('Saved')
      }
    }
  },
  methods: {
    isNullwithSpace (str: string){
      var regu = /^[ ]+$/;
      var re = new RegExp(regu);
      return re.test(str);
    },
    setBorder () {
      if(this.isLegal){
        return 'in_course'
      }else {
        return 'in_course_error'
      }
    },
    crtnCourseDialog_butDone () : void {
      if(this.isLegal){
        this.$emit('closeDialog', true, this.Name)
      }
    },
    crtnCourseDialog_butCancel () : void {
      this.$emit('closeDialog', false)
    }
  },
  created() {
    this.Name = this.name
    if (this.type === "edit_unit") {
      this.header_title = '編輯單元名稱'
      this.title_name = '單元名稱'
      this.place_holder = '輸入單元名稱'
    }
    this.old_name = this.Name
  }
})
