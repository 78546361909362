// import { state, AnalyzedScore } from './state';

import { LoginState, MutationTypes, ThemesState } from "../type-defs"

export const mutations = {
    [MutationTypes.Theme.SEARCH] (state: ThemesState, target: string): void {
        console.log('do SEARCH')
        state.searchTarget = target
    },
    [MutationTypes.Theme.SET_DATA] (state: ThemesState, target: any): void {
        console.log('do SET_DATA')
        state.data = target
    },


}
