export const MutationTypes = {
  App:{
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    GET_TOKEN: 'GET_TOKEN'
  },
  Theme:{
    SEARCH: 'SEARCH',
    SET_DATA: 'SET_DATA'

  }
}

export const NameSpace = {
  App: 'app/',
  ZLAC: 'zlac/',
  Theme: 'theme/',
  Unit: 'unit/'

}
