import './router/hooks'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'conveditor/dist/conveditor.css'
import VueRouter from 'vue-router'
import EventBus from 'vue-bus-ts'
import VueCookies from 'vue-cookies'
let VueClosable = require('vue-closable')

Vue.use(ElementUI)
Vue.use(EventBus)
Vue.use(VueCookies)
Vue.use(VueClosable)

var bus = new EventBus.Bus();

Vue.config.productionTip = false
declare module 'vue/types/vue' {
  interface Vue {
    $router: VueRouter
  }
}
new Vue({
  router,
  store,
  bus,
  render: h => h(App)
}).$mount('#app')
