import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators';
import {getModule} from 'vuex-module-decorators';
import store from '@/store';
@Module({dynamic: true, store, name: 'course'})
export default class Course extends VuexModule {
  id = '';
  title = '';
  @Mutation
  private SET_TITLE(title: string) {
    this.title = title;
  }

  @Mutation
  private SET_ID(id: string) {
    this.id = id;
  }

  @Action
  reset() {
    this.SET_TITLE('');
    this.SET_ID('');
  }

  @Action
  setId(id: string) {
    this.SET_ID(id);
  }

  @Action
  setTitle(title: string) {
    this.SET_TITLE(title);
  }
}

export const CourseModule = getModule(Course);
