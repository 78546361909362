import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
  },
  {
    path: '/editUnit/:courseId/:unitId/:courseTitle/:category?',
    name: 'editUnit',
    component: () => import(/* webpackChunkName: "editUnit" */ '../views/editUnit.vue')
  },
  {
    path: '/courseList',
    name: 'courseList',
    component: () => import(/* webpackChunkName: "courseList" */ '../views/CourseList.vue')
  },
  {
    path: '/listen_read/:course_id/:unit_id/:course_title',
    name: 'listen_read',
    component: () => import(/* webpackChunkName: "courseList" */ '../views/ListenRead.vue')
  },
  {
    path: '/groupList',
    name: 'groupList',
    component: () => import(/* webpackChunkName: "groupList" */ '../views/GroupList.vue')
  },
  {
    path: '/memberList',
    name: 'memberList',
    component: () => import(/* webpackChunkName: "memberList" */ '../views/MemberList.vue'),
    props:true
  },
  {
    path: '/groupSearchResult',
    name: 'groupSearchResult',
    component: () => import(/* webpackChunkName: "groupSearchResult" */ '../views/GroupSearchResult.vue'),
    props:true
  },
  {
    path: '/memberListHistory/:groupId/:groupNameValue?',
    name: 'memberListHistory',
    component: () => import(/* webpackChunkName: "memberListHistory" */ '../views/MemberListHistory.vue'),
    props:true
  },
  {
    path: '/memberHistory/:userId/:userNameValue/:accountValue?',
    name: 'memberHistory',
    component: () => import(/* webpackChunkName: "memberHistory" */ '../views/MemberHistory.vue'),
    props:true
  },
  {
    path: '/unitList/:courseId/:courseTitle?',
    name: 'unitList',
    component: () => import(/* webpackChunkName: "unitList" */ '../views/UnitList.vue'),
    props:true
  },
  {
    path: '/conveditor/:courseId/:unitId?',
    name: 'conveditor',
    component: () => import(/* webpackChunkName: "conveditor" */ '../views/ConvEditor.vue')
  },
  {
    path: '/unitHistory/:groupId/:userId/:courseId/:unitId/:unitTitle?',
    name: 'unitHistory',
    component: () => import(/* webpackChunkName: "unitHistory" */ '../views/UnitHistory.vue'),
    props:true
  },
  {
    path: '/404',
    name:'notfound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  },
  {
    path: '*',
    name:'pagenotfound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.onError(error => {
  const pattern1 = /Loading chunk/g;
  const pattern2 = /failed/g;
  const isChunkLoadFailed1 = error.message.match(pattern1);
  const isChunkLoadFailed2 = error.message.match(pattern2);
  try {
    if (isChunkLoadFailed1 && isChunkLoadFailed2) {
      let LoadingChunk = Number(window.sessionStorage.getItem('LoadingChunk') || '0');
      if (LoadingChunk == 0) {
        window.sessionStorage.setItem('LoadingChunk', "1");
        window.location.reload();
      }
      if (LoadingChunk == 1) {
        window.sessionStorage.setItem('LoadingChunk', "0");
        alert('請重新整理頁面！');
      }
    }
  } catch (e) {
    console.log(e);
  }
})

export default router
