export const enum TYPE_EXAM_CATEGORY{
    story_conversation='{"id":"T001","item_name":"情境對話"}',setence='{"id":"S001","item_name":"句子"}',conversation='{"id":"S002","item_name":"會話"}',listen_read='{"id":"T002","item_name":"情境聽讀"}'
}

export function getTypeExamCategoryID(category:TYPE_EXAM_CATEGORY){
    return JSON.parse(category).id
}
export function getTypeExamCategoryName(category:TYPE_EXAM_CATEGORY){
    return JSON.parse(category).item_name
}

export const LISTEN_READ_LEVEL={
    STARTER:{CEFR:'CEFR PreA',NAME:'Starter'},
    BEGINNER:{CEFR:'CEFR A1',NAME:'Beginner1'}
}

export const story_time=[
    {seconds:30, words:50, text:'30秒'},
    {seconds:60,words:100, text:'1分鐘'},
    {seconds:90,words:150, text:'1分30秒'}
]

export const enum EXAM{
    TEST='評測',DIALOG='情境對話'
}

export const listen_read_topic=
[
    {
        "eng": "Simple daily life conversation",
        "chinese": "簡單日常生活用語",
        "sub_topic": [
            {
                "eng": "Greetings and Introductions",
                "chinese": "問候和介紹"
            },
            {
                "eng": "Expressing Needs and Wants",
                "chinese": "表達需求"
            },
            {
                "eng": "Apologizing and Thanking",
                "chinese": "道歉和感謝"
            }
        ]
    },
    {
        "eng": "Individual and family life",
        "chinese": "個人與家庭生活",
        "sub_topic": [
            {
                "eng": "Family Members",
                "chinese": "家庭成員"
            },
            {
                "eng": "Family History",
                "chinese": "家庭歷史"
            },
            {
                "eng": "Family Activities",
                "chinese": "家庭活動"
            }
        ]
    },
    {
        "eng": "Body parts and related actions",
        "chinese": "身體部位與相關動作",
        "sub_topic": [
            {
                "eng": "Injury and First Aid",
                "chinese": "意外傷害處理"
            },
            {
                "eng": "Exercise and Fitness",
                "chinese": "運動健身"
            },
            {
                "eng": "Personal Abilities",
                "chinese": "個人能力"
            }
        ]
    },
    {
        "eng": "Personal hygiene and health habits",
        "chinese": "個人清潔衛生健康習慣",
        "sub_topic": [
            {
                "eng": "Daily Hygiene Routines",
                "chinese": "每日衛生習慣"
            },
            {
                "eng": "Healthy Eating Habits",
                "chinese": "健康飲食習慣"
            },
            {
                "eng": "Visiting the Doctor",
                "chinese": "看醫生"
            }
        ]
    },
    {
        "eng": "Classroom and school life",
        "chinese": "教室與學校生活",
        "sub_topic": [
            {
                "eng": "Classroom Objects",
                "chinese": "課堂物品"
            },
            {
                "eng": "School Subjects",
                "chinese": "學校科目"
            },
            {
                "eng": "School Events",
                "chinese": "學校活動"
            }
        ]
    },
    {
        "eng": "Colors",
        "chinese": "顏色",
        "sub_topic": [
            {
                "eng": "Colors in Culture",
                "chinese": "文化中的顏色"
            },
            {
                "eng": "Describing Objects by Color",
                "chinese": "用顏色描述物品"
            },
            {
                "eng": "Favorite Colors",
                "chinese": "喜歡的顏色"
            }
        ]
    },
//    {
//        "eng": "Numbers and their applications",
//        "chinese": "數字與應用",
//        "sub_topic": [
//            {
//                "eng": "Using Money",
//                "chinese": "運用金錢"
//            },
//            {
//                "eng": "Measuring Objects",
//                "chinese": "測量物體"
//            },
//            {
//                "eng": "math games",
//                "chinese": "數學遊戲"
//            }
//        ]
//    },
    {
        "eng": "Daily routines and needs",
        "chinese": "生活常規、需求",
        "sub_topic": [
            {
                "eng": "Morning Routines",
                "chinese": "晨間例行公事"
            },
            {
                "eng": "Personal Responsibilities",
                "chinese": "個人責任"
            },
            {
                "eng": "Planning a Day",
                "chinese": "每日計畫"
            }
        ]
    },
    {
        "eng": "Feelings, preferences, and emotions",
        "chinese": "感覺、喜好與感情",
        "sub_topic": [
            {
                "eng": "Positive Thinking",
                "chinese": "正向思考"
            },
            {
                "eng": "Describing How You Feel",
                "chinese": "描述感受"
            },
            {
                "eng": "Likes and Dislikes",
                "chinese": "喜好和厭惡"
            }
        ]
    },
    {
        "eng": "Home and leisure activities",
        "chinese": "居家與休閒活動",
        "sub_topic": [
            {
                "eng": "Indoor Activities",
                "chinese": "室內活動"
            },
            {
                "eng": "Outdoor Fun",
                "chinese": "戶外樂趣"
            },
            {
                "eng": "Playing Games",
                "chinese": "玩遊戲"
            }
        ]
    },
    {
        "eng": "Food and dining",
        "chinese": "食物與餐飲",
        "sub_topic": [
            {
                "eng": "Table Manners",
                "chinese": "餐桌禮儀"
            },
            {
                "eng": "Eating Out",
                "chinese": "外食"
            },
            {
                "eng": "Cooking and Recipes",
                "chinese": "烹飪和食譜"
            }
        ]
    },
    {
        "eng": "Transportation and vehicles",
        "chinese": "交通工具與運輸",
        "sub_topic": [
            {
                "eng": "Public Transport",
                "chinese": "大眾運輸"
            },
            {
                "eng": "Safety Rules",
                "chinese": "安全規則"
            },
            {
                "eng": "Favorite Modes of Transport",
                "chinese": "喜歡的交通方式"
            }
        ]
    },
    {
        "eng": "Shopping",
        "chinese": "購物",
        "sub_topic": [
            {
                "eng": "Going to the Market",
                "chinese": "去市場"
            },
            {
                "eng": "Asking for Prices",
                "chinese": "詢問價格"
            },
            {
                "eng": "Shopping Lists",
                "chinese": "購物清單"
            }
        ]
    },
    {
        "eng": "Community facilities and life",
        "chinese": "社區設施與生活",
        "sub_topic": [
            {
                "eng": "Visiting the Library",
                "chinese": "參觀圖書館"
            },
            {
                "eng": "Going to the Park",
                "chinese": "去公園"
            },
            {
                "eng": "Using the Post Office",
                "chinese": "郵局服務"
            }
        ]
    },
    {
        "eng": "Occupations and work",
        "chinese": "職業與工作",
        "sub_topic": [
            {
                "eng": "Where People Work",
                "chinese": "工作場合"
            },
            {
                "eng": "Describing Jobs",
                "chinese": "描述工作"
            },
            {
                "eng": "Dream Jobs",
                "chinese": "理想職業"
            }
        ]
    },
    {
        "eng": "Character descriptions",
        "chinese": "人物描述",
        "sub_topic": [
            {
                "eng": "Story Characters",
                "chinese": "故事角色"
            },
            {
                "eng": "Role Models",
                "chinese": "模範楷模"
            },
            {
                "eng": "Describing Friends",
                "chinese": "描述朋友"
            }
        ]
    },
    {
        "eng": "Object descriptions",
        "chinese": "物品描述",
        "sub_topic": [
            {
                "eng": "Naming Objects",
                "chinese": "指認物品"
            },
            {
                "eng": "Uses of Objects",
                "chinese": "物品用途"
            },
            {
                "eng": "Comparing Objects",
                "chinese": "比較物件"
            }
        ]
    },
    {
        "eng": "Nature and weather",
        "chinese": "大自然與天氣",
        "sub_topic": [
            {
                "eng": "Types of Weather",
                "chinese": "天氣種類"
            },
            {
                "eng": "Protecting Nature",
                "chinese": "保護自然"
            },
            {
                "eng": "Natural Disasters",
                "chinese": "自然災害"
            }
        ]
    },
    {
        "eng": "Relationship between seasonal changes and life",
        "chinese": "季節變化與生活之關係",
        "sub_topic": [
            {
                "eng": "Seasonal Foods",
                "chinese": "季節性食物"
            },
            {
                "eng": "Seasonal Sports",
                "chinese": "季節性運動"
            },
            {
                "eng": "Seasonal Clothing",
                "chinese": "季節性服裝"
            }
        ]
    },
    {
        "eng": "Time and schedules",
        "chinese": "時間與作息",
        "sub_topic": [
            {
                "eng": "Time Zones",
                "chinese": "時區"
            },
            {
                "eng": "School Timetable",
                "chinese": "課表"
            },
            {
                "eng": "Time Management",
                "chinese": "時間管理"
            }
        ]
    },
    {
        "eng": "Interests and hobbies",
        "chinese": "興趣與嗜好",
        "sub_topic": [
            {
                "eng": "Reading and Writing",
                "chinese": "閱讀和寫作"
            },
            {
                "eng": "Sports and Games",
                "chinese": "運動和遊戲"
            },
            {
                "eng": "Art and Music",
                "chinese": "藝術和音樂"
            }
        ]
    },
    {
        "eng": "Common animals and plants",
        "chinese": "常見動物與植物",
        "sub_topic": [
            {
                "eng": "Pets and Farm Animals",
                "chinese": "寵物和農場動物"
            },
            {
                "eng": "Plant Life Cycle",
                "chinese": "植物生命周期"
            },
            {
                "eng": "Caring for Plants",
                "chinese": "照顧植物"
            }
        ]
    },
    {
        "eng": "Fairy tales",
        "chinese": "童話故事",
        "sub_topic": [
            {
                "eng": "Classic Stories",
                "chinese": "經典故事"
            },
            {
                "eng": "Magical Creatures",
                "chinese": "魔法生物"
            },
            {
                "eng": "Morals and Lessons",
                "chinese": "道德和教訓"
            }
        ]
    },
    {
        "eng": "Computer technology",
        "chinese": "電腦科技",
        "sub_topic": [
            {
                "eng": "Artificial Intelligence",
                "chinese": "人工智慧"
            },
            {
                "eng": "Using the Internet",
                "chinese": "使用網際網路"
            },
            {
                "eng": "Digital Safety",
                "chinese": "資訊安全"
            }
        ]
    },
    {
        "eng": "Festivals and social customs",
        "chinese": "節慶與社會風俗",
        "sub_topic": [
            {
                "eng": "International Festivals",
                "chinese": "國際節日"
            },
            {
                "eng": "Holiday Celebrations",
                "chinese": "慶祝節日"
            },
            {
                "eng": "Traditional Clothing",
                "chinese": "傳統服飾"
            }
        ]
    }
]
  export const sentences_conversation_topic=[
    {chinese:'自我介紹',eng:'self-introduction'},
    {chinese:'年齡',eng:'age'},
    {chinese:'職業',eng:'job/what do you do'},
    {chinese:'國家',eng:'where you come from'},
    {chinese:'家庭成員',eng:'family'},
    {chinese:'天氣',eng:'weather'},
    {chinese:'時間',eng:'month, date, time'},
    {chinese:'地點/各種場所',eng:'where are you/place'},
    {chinese:'假期',eng:'holiday/vacation'},
    {chinese:'顏色',eng:'color'},
    {chinese:'形狀',eng:'shape'},
    {chinese:'味道',eng:'flavor'},
    {chinese:'數量',eng:'number/amount'},
    {chinese:'食物',eng:'food'},
    {chinese:'動物',eng:'animal'},
    {chinese:'寵物',eng:'pet'},
    {chinese:'運動',eng:'sport/exercise'},
    {chinese:'交通',eng:'transportation'},
    {chinese:'物品',eng:'stationery/cloth'},
    {chinese:'學校課程',eng:'course'},
    {chinese:'病症',eng:'sick/symptom'},
    {chinese:'感覺/情緒',eng:'feeling/emotion'},
    {chinese:'回憶',eng:'memory'},
    {chinese:'指稱',eng:'what is it/this/that, who is he/she'},
    {chinese:'問路',eng:'direction'},
    {chinese:'日常/休閒活動',eng:'leisure'},
    {chinese:'日常作息',eng:'daily life,sleep and wakeup'},
    {chinese:'你喜歡什麼',eng:'what do you like'}
]