import Vue from "vue";
//import repository from "./repository";
const myVue: any = Vue
const userResource = '/api/v1/user'
//import Axios, { AxiosPromise } from "axios";
import { NormalResponse, ServerResponse } from "./type-defs";

export const LoginAPI = {
  // GetUserDataByTicket,
  getCookie,
  cleanCookie,
  checkLogin,
}

function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function cleanCookie() {
  const date = new Date();
  date.setTime(date.getTime() + (-10 * 24 * 60 * 60 * 1000));
  document.cookie = 'aticket' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT, path=/asus; domain=.asus.com; secure;'
}

function checkLogin(): Boolean {
  return (getCookie('aticket') !== '')
}