import Vue from 'vue'
// import uploadAPI from '../../api/uploadAPI'
import { LoginState, MutationTypes } from '../type-defs'
import { ActionContext } from 'vuex'
// import repository from '@/src/api/repository';
// import queryAPI from '../../api/queryAPI';

const myVue: any = Vue

export const actions = {
  
}



